import {useTranslation} from "react-i18next";
import {Marker} from "../interfaces/MarkersResult";
import {isMobile} from "../services/Utilities";
import {useEffect, useRef, useState} from "react";
import {StorePin} from "./StorePin";


interface ClosestPointModalProps {
  point: Marker | undefined;
  distance?: number;
  map?: google.maps.Map | undefined;
  closeTab?: () => void;
  platform?: "storelocator" | "iframe";
  pinSVG?: string,
  text?: string;

}

export const ClosestPointModal = ({
                                    point,
                                    distance,
                                    map,
                                    closeTab,
                                    platform = "storelocator",
                                    pinSVG,
                                    text

                                  }: ClosestPointModalProps) => {
  const {t} = useTranslation();
  const _isMobile = isMobile();
  const animateRef = useRef<HTMLDivElement>(null);
  const injectStyle = (style: string) => {
    const styleElement = document.createElement("style");
    let styleSheet = null;
    document.head.appendChild(styleElement);
    styleSheet = styleElement.sheet;
    styleSheet?.insertRule(style, styleSheet.cssRules.length);
  };

  const marqueeWidth = document.getElementById("marquee")?.clientWidth ?? 0;
  const [translatedPx, setTranslatedPx] = useState(0);
  useEffect(() => {
    if (animateRef.current)
      setTranslatedPx(animateRef.current.scrollWidth - marqueeWidth);
  }, [marqueeWidth]);
  const marqueeStyle = `
      @keyframes marquee {
        0%   { transform: translate(0%) }
        50% { transform: translate(${-translatedPx}px) }
        100%{ transform: translate(0%) }
      }
    `;
  injectStyle(marqueeStyle);
  const clickHandler = () => {
    map?.setCenter({
      lat: Number(point?.lat),
      lng: Number(point?.lng)
    });
    closeTab && closeTab();
  };
  if (!point) return null;
  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
      {platform === "iframe" && <div style={{
        background: "#47C09B",
        maxWidth: "160px",
        fontSize: "12px",
        padding: "4px 16px",
        borderRadius: "8px",
        position: "relative",
        top: "14px",
        color: "#fff",
      }}>{t("tapForDirections")}
      </div>}
      <div
        style={{
          borderRadius: "22.054px",
          border: platform === "storelocator" ? "2px solid #BAE0D5" : "2px solid #DFF0FF",
          background: "#FFF",
          boxShadow:
            "0px 340px 95.568px 0px rgba(14, 65, 51, 0.00), 0px 216.865px 86.378px 0px rgba(14, 65, 51, 0.01), 0px 123.135px 73.514px 0px rgba(14, 65, 51, 0.05), 0px 55.135px 55.135px 0px rgba(14, 65, 51, 0.09), 0px 12.865px 29.405px 0px rgba(14, 65, 51, 0.10)",
          position: platform === "storelocator" ? "fixed" : "static",
          height: _isMobile ? "80px" : platform === "storelocator" ? "140px" : "auto",
          width: _isMobile ? "70vw" : platform === "storelocator" ? "40vw" : "auto",
          left: platform === "storelocator" ? "50%" : "auto",
          bottom: platform === "storelocator" ? "30px" : "auto",
          transform: platform === "storelocator" ? "translate(-50%)" : "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: _isMobile ? "flex-start" : "space-evenly",
          gap: _isMobile ? "10px" : "",
          paddingLeft: _isMobile ? "10px" : "",
          color: "#000",
          paddingTop: "8px"
        }}
        onClick={clickHandler}
      >
        <StorePin
          style={{
            height: _isMobile ? "32px" : "48px",
            margin: "12px"
          }}
          pinSVG={pinSVG}
        />
        <div
          style={{
            textAlign: "left",
            maxWidth: _isMobile ? "50vw" : "30vw"
          }}
        >
          {text ? text : <>
            <div
              style={{
                fontSize: _isMobile ? "12px" : platform === "storelocator" ? "20px" : "14px",
                fontWeight: 400,
                lineHeight: _isMobile ? "16px" : "24px",
                color: platform === "storelocator" ? "#000" : "##373C3F",

              }}
            >
              {`${t("closestModalTitle")}:`}
            </div>

            <div
              className={"marquee"}
              id={"marquee"}
              style={{
                fontSize: _isMobile ? "16px" : platform === "storelocator" ? "28px" : "22px",
                fontWeight: platform === "iframe" || _isMobile ? 500 : 700,
                lineHeight: platform === "storelocator" ? _isMobile ? "24px" : "33.6px" : "21px",
                color: platform === "storelocator" ? "#000" : "#485256",
                whiteSpace: "nowrap",
                overflowX: "scroll",
                display: "flex",
                scrollbarWidth: "none"
              }}
            >
          <span
            ref={animateRef}
            onMouseEnter={() => animateRef.current?.getAnimations()[0]?.pause()}
            onMouseLeave={() => animateRef.current?.getAnimations()[0]?.play()}
            style={{
              animation:
                animateRef.current !== null &&
                animateRef.current.clientWidth > marqueeWidth
                  ? "marquee 10s linear infinite"
                  : "none",
              display: "inline-block",
              whiteSpace: "nowrap"
            }}
          >
            {point.name}
          </span>
            </div>
            {distance ? (
              <div
                style={{
                  fontSize: _isMobile ? "14px" : platform === "storelocator" ? "28px" : "16px",
                  fontWeight: 400,
                  lineHeight: _isMobile ? "20px" : "33.6px",
                  color: platform === "storelocator" ? "#000" : "#627378",
                }}
              >
                {distance >= 1000
                  ? Math.round(distance / 100) / 10
                  : Math.round(distance)}
                {distance >= 1000 ? "km" : "m"}
              </div>
            ) : null}</>}
        </div>
      </div>
    </div>
  );
};
